<template lang='pug'>
	.d-flex.b3.text-nowrap.tag(:class="[{'active': active}, `${color}`]" @click="active = !active, $emit('isActive', active)") {{ text }}
		i.fontello.fontello-icon-cross-bold(v-if="active" :style="{color: isDarkColor ? '#808080' : '#082846'}")
</template>

<script>

export default {
	name: 'Tag',
	props: {
		text: {
			type: String,
			default: ''
		},
		isActive: {
			type: Boolean,
			default: false
		},
		color: {
			type: String,
			default: ''
		}
	},
	data: () => ({
		active: false,
	}),
	mounted() {
		this.active = this.isActive;
	},
	watch: {
		isActive(val) {
			this.active = val;
		}
	}
}
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.tag {
	display: flex;
	align-items: center;
	height: 32px;
	padding: 4px 15px;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	border-radius: 20px;
	transition: var(--animation-time-short);
	border: 1px solid var(--grayscale-line-border);
	cursor: pointer;

	i {
		font-size: 10px;
		margin-left: 10px;
	}

	&.bold {
		font-weight: 500;
	}

	&.active {
		background-color: var(--brand-bg-light-green);
		border-color: transparent;

		&.primary {
			background-color: var(--brand-light-bg-primary);
		}
	}
}
</style>
